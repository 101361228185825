import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules/index.mjs';
import { debounce } from 'lodash';
import { removeHiddenAssets } from './util';

const SWIPERID = '.js-cities-slider';

const CitiesSlider = (() => {
    let mySwiper;

    function handleSwiperCities() {
        if (!$(SWIPERID).length > 0) {
            return;
        }

        removeHiddenAssets($(SWIPERID), '.swiper-slide');

        const numberOfSlides = $(SWIPERID).find('.swiper-slide').length;

        const destroyParams = {
            cleanStyles: true,
        };

        if (typeof mySwiper !== 'undefined') {
            mySwiper.destroy(destroyParams);
        }

        mySwiper = undefined;

        const options = {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, Scrollbar, A11y],
            spaceBetween: 16,
            slidesPerView: 'auto',
            breakpointsInverse: true,
            watchOverflow: true,
            freeMode: true,
            pagination: {
                el: '.js-cities-slider-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.js-cities-slider-next',
                prevEl: '.js-cities-slider-prev',
            },
            a11y: {
                prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
            },
        };

        if ($(window).width() < 901 && numberOfSlides > 5) {
            options.pagination = false;
            $('.js-cities-slider-navigation').addClass('mobile-scrollbar');

            options.scrollbar = {
                el: '.js-cities-slider-scrollbar',
                draggable: true,
            };
        } else {
            $('.js-cities-slider-navigation').removeClass('mobile-scrollbar');
        }

        if (typeof mySwiper === 'undefined') {
            mySwiper = new Swiper(SWIPERID, options);
        }
    }

    return {
        init() {
            handleSwiperCities();
        },
        resize() {
            handleSwiperCities();
        },
    };
})();

export default (() => {
    $(CitiesSlider.init);
    $(window).on('resize', debounce(CitiesSlider.resize, 300));
})();
